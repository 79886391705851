import { Component, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TeamPreferenceService } from '../../shared/services/team-preference.service';
import { Team } from '../../shared/models/Team';

@Component({
    selector: 'app-change-team-preference-dialog',
    templateUrl: './change-team-preference-dialog.component.html',
    styleUrls: ['./change-team-preference-dialog.component.scss'],
    providers: [ TeamPreferenceService ]
})
export class ChangeTeamPreferenceDialogComponent implements OnInit {
    
    public currentTeamId: number | undefined = undefined;

    constructor( 
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private teamPreferenceService: TeamPreferenceService
    ) { }

    ngOnInit(): void {

        this.currentTeamId = this.dialogConfig.data?.currentTeamId;
    }

    public onTeamSelected(team: Team | undefined): void {

        if ( !team ) {

            return this.close();
        } 

        this.teamPreferenceService.updatePreference(team).subscribe(
            
            (teamPreference) => {

                this.teamPreferenceService.updatePreferenceInLocalStorage(teamPreference);
                this.close(team);
                this.refresh();
            },
            (err) => {

                this.close();
                throw err;
            }
        );
    }

    public close(team?: Team): void {

        this.dialogRef.close(team || undefined);
    }
    
    private refresh(): void {

        if ( window && window.location ) {

            window.location.reload();
        }
    }
}
